import React, {useState} from 'react';
import * as yup from 'yup';
import {useModal} from "./ModalContext";

const loginSchema = yup.object().shape({
    identifier: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
});

const LoginForm = ({onLogin, onUserUpdate}) => {
    const [userData, setUserData] = useState({identifier: '', password: ''});
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState('');
    const {setShowLoginModal, setShowRegisterModal, setShowPasswordRetrievalModal} = useModal();


    const handleOpenPasswordRetrieval = () => {
        setShowLoginModal(false);
        setShowPasswordRetrievalModal(true);
    };

    const handleSwitchToRegister = () => {
        setShowLoginModal(false);
        setShowRegisterModal(true);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserData({...userData, [name]: value});
        if (errors[name]) {
            setErrors({...errors, [name]: ''});
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoginError('');

        try {
            await loginSchema.validate(userData, {abortEarly: false});

            const {error} = await onLogin(userData);

            if (error) {
                setLoginError(error);
            } else {
                setShowLoginModal(false);
            }
        } catch (yupError) {
            if (yupError && yupError.inner) {
                const newErrors = yupError.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {});
                setErrors(newErrors);
            } else {
                console.error('An unexpected error occurred:', yupError);
                setLoginError('An unexpected error occurred. Please try again.');
            }
        }
    };


    return (
        <div className={"formContainer"}>
            <h1>Welcome back!</h1>
            <p className={"greetings"}>Welcome back to Keep It Safe 24/7!<br/>
                Login to book new lockers or manage existing bookings.</p>
            <form onSubmit={handleSubmit}>
                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="text"
                            name="identifier"
                            value={userData.identifier}
                            onChange={handleChange}
                            placeholder={"Email"}
                        />
                    </div>
                    {errors.identifier && <p className="errorMessage">{errors.identifier}</p>}
                </div>

                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="password"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            placeholder={"Password"}

                        />
                    </div>
                    {errors.password && <p className="errorMessage">{errors.password}</p>}
                </div>

                {loginError && <p className="errorMessage">{loginError}</p>}

                <button className={"c-button formButton"} type="submit">Login</button>
            </form>
            <button className={"d-button"} onClick={handleOpenPasswordRetrieval}>Retrieve Password</button>
            <p className="switchFormText">
                Don't have an account? <button className={"b-button"} onClick={handleSwitchToRegister}>Sign Up</button>
            </p>
        </div>
    );
};

export default LoginForm;
