import React from 'react';
import {GoogleMap, useJsApiLoader, MarkerF} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const markerPosition = {
    lat: 32.648604,
    lng: -16.911673
};

function MapComponent() {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={markerPosition}
            zoom={15}
        >
            <MarkerF position={markerPosition}/>
        </GoogleMap>
    ) : <div>Loading Map...</div>;
}

export default React.memo(MapComponent);
