import React, {createContext, useState, useContext, useEffect} from 'react';
import Cookies from 'js-cookie';
import {getUserDetails} from '../../services/api';

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);


export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = Cookies.get('jwt');
        if (token) {
            getUserDetails(token)
                .then((data) => {
                    setUser(data);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, []);

    const handleUserUpdate = (newUser) => {
        setUser(newUser);
    };

    const handleLogout = () => {
        Cookies.remove('jwt');
        setUser(null);
    };

    return (
        <UserContext.Provider value={{user, isLoading, handleUserUpdate, handleLogout}}>
            {children}
        </UserContext.Provider>
    );
};
