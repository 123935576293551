import CookieConsent from "react-cookie-consent";
import "./CookieConsent.scss";
import Modal from "../Modal/Modal";
import React, {useState} from "react";

const CookieConsentComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleAccept = () => {
        // Logic for accepting cookies
        // Set all types of cookies here
    };

    const handleDecline = () => {
        // Logic for declining cookies
        // Ensure non-essential cookies are not set
    };

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept all cookies"
            declineButtonText="Deny"
            onAccept={handleAccept}
            onDecline={handleDecline}
            enableDeclineButton
            cookieName="goncalvesPestanaCookies"
            containerClasses="cookie-consent-container"
            buttonClasses="cookie-accept-button"
            declineButtonClasses="cookie-decline-button"
            expires={365}
        >
            GONÇALVES & PESTANA, LDA. uses cookies and similar technologies for service improvement and statistical purposes. By clicking "Accept all cookies", you consent to our use of cookies.
            <button className={"b-button"} onClick={() => setIsModalOpen(true)}>
                Read more
            </button>
            <Modal isOpen={isModalOpen}
                   onClose={() => setIsModalOpen(false)}>
                <div className={"cookieModalContainer"}>
                    <h1>Cookie Policy </h1>
                    <p>
                        GONÇALVES & PESTANA, LDA. uses cookies and similar technologies in its
                        technologies in the respective domains to ensure the improvement of the provision
                        of the service and the experience of using the website, to ensure the
                        operation of its website, as well as for statistical purposes, such as measuring activity on
                        different devices and evaluating search, as well as for the display and evaluation of content and
                        advertising, possibly by third-party companies and on third-party websites.
                    </p>
                    <p>
                        The cookies used do not collect information about the visitor to the website, but rather on how
                        they use the website.
                    </p>
                    <p>
                        By clicking on "Accept all cookies", you give us your consent to the use of non-essential and
                        non-functional cookies and similar technologies, as well as to exchange information generated
                        online with each other to carry out advertising campaigns and the associated processing of
                        personal data.
                    </p>
                    <h3>
                        WHAT TYPES OF COOKIES ARE USED?
                    </h3>
                    <ol>
                        <li>Analytical Cookies:
                            <p>These are used for the purpose of creating and analyzing statistics, with
                                the aim of maintaining the proper functioning of the website, without ever collecting personal
                                information</p>
                        </li>
                        <li>Functionality Cookies:
                            <p>
                                These store the user's preferences regarding the use of the website, so
                                that it is not necessary to re-configure the site each time you visit it.
                            </p>
                        </li>
                        <li>Third-party cookies:
                            <p>These are created from third-party services, such as Google, Youtube and
                                Facebook. They can be used to show you advertising related to your actions on the website.</p>
                        </li>
                    </ol>
                    <p>
                        For any other question related to the protection of the cookies used, you can contact us at the
                        following e-mail address: <a style={{color: "#186AB2"}} href="mailto:keepitsafe247@hotmail.com">keepitsafe247@hotmail.com</a>.
                    </p>
                </div>
            </Modal>
        </CookieConsent>
    );
};

export default CookieConsentComponent;
