import "./Modal.scss";
const Modal = ({isOpen, onClose, children}) => {
    if (!isOpen) return null;
    const handleOverlayClick = (e) => {
        onClose();
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };
    return (
        <div className="overlay_modal" onClick={handleOverlayClick}>
            <div className="modal" onClick={handleModalClick}>
                <div className="modalHeader">
                    <button className={"closeButton"} onClick={onClose}>X</button>
                </div>
                {children}
            </div>
        </div>
    );
};
export default Modal;
