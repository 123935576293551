import React, {useState} from 'react';
import './FaqItem.scss';
import {motion} from 'framer-motion';

const FaqItem = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    const variants = {
        open: {opacity: 1, height: 'auto', y: 0},
        closed: {opacity: 0, height: 0, y: 30}
    };


    return (
        <div className="faqItem">
            <div className="faqQuestion" onClick={toggleOpen}>
                {question}
                <img src="/imgs/arrow.svg" className={`arrowIcon ${isOpen ? 'open' : ''}`} alt="↓"/>
            </div>
            <motion.div
                className="faqAnswer"
                initial="closed"
                animate={isOpen ? "open" : "closed"}
                variants={variants}
                transition={{duration: 0.8, ease: "easeInOut"}}
            >
                {isOpen && <p>{answer}</p>}
            </motion.div>
        </div>
    );
};

export default FaqItem;
