export const formatEndTime = (endTime) => {
    if (!endTime) return '';

    const date = new Date(endTime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year}, ${hours}h${minutes}`;
};

export const getEndTime = (duration) => {
    if (!duration) return '';

    const now = new Date();
    const hoursToAdd = parseInt(duration, 10);
    now.setHours(now.getHours() + hoursToAdd);

    return formatEndTime(now);
};

export const calculateIntervals = (timeDifference) => {
    const intervals = Math.ceil(Math.abs(timeDifference) / 30);
    return `${intervals} x 30 minutes ${intervals === 1 ? '(minimum)' : ''}`;
};

export const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'});
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${formattedDate}, ${hours}h${minutes}`;
};

export const formatTimeLeft = (timeDiff, isLessThanAnHour, isOvertime = false) => {
    const absTimeDiff = Math.abs(timeDiff);
    if (isLessThanAnHour) {
        return `${absTimeDiff} minutes ${isOvertime ? "" : "left"}`;
    } else {
        const hours = Math.floor(absTimeDiff / 60);
        const minutes = absTimeDiff % 60;
        return `${hours}h${minutes < 10 ? '0' : ''}${minutes} left`;
    }
};

export const formatTimeDifference = (timeDiffInMinutes) => {
    const hours = Math.floor(Math.abs(timeDiffInMinutes) / 60);
    const minutes = Math.abs(timeDiffInMinutes) % 60;

    if (hours === 0) {
        return `${minutes} minutes`;
    } else {
        return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
    }
};