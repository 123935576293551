import React, {useEffect, useState} from 'react';
import {useUser} from '../Auth/UserContext';
import {Navigate} from 'react-router-dom';
import Footer from "../Footer/Footer";
import Cookies from "js-cookie";
import "./RentalsPage.scss";
import {differenceInMinutes, parseISO} from 'date-fns';
import Modal from "../Modal/Modal";
import {loadStripe} from "@stripe/stripe-js";
import {calculateIntervals, formatDateTime, formatTimeLeft, formatTimeDifference} from '../../services/timeFormat';
import OverduePaymentModal from "./OverduePaymentModal";
import ReturnModal from "./ReturnModal";
import {motion} from 'framer-motion';

const Tabs = ({children}) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const handleClick = (e, newActiveTab) => {
        e.preventDefault();
        setActiveTab(newActiveTab);
    };

    return (
        <div>
            <ul className="tabs">
                {children.map((tab) => (
                    <li
                        key={tab.props.label}
                        className={tab.props.label === activeTab ? 'active' : ''}
                        onClick={(e) => handleClick(e, tab.props.label)}>
                        {tab.props.label}
                    </li>
                ))}
            </ul>
            <div className="tab-content">
                {children.map((content) => {
                    if (content.props.label !== activeTab) return undefined;
                    return content.props.children;
                })}
            </div>
        </div>
    );
};

const Tab = ({children}) => {
    return <>{children}</>;
};

export const fetchRentals = async () => {
    const token = Cookies.get('jwt');

    if (!token) {
        throw new Error('No token found');
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rents/user-rents`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        return result;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};
const RentalRow = ({rental, setRentUpdated, lockerUpdate}) => {
        const [isRentalActive, setIsRentalActive] = useState(rental?.isActive);
        const [isDoorOpenModalVisible, setDoorOpenModalVisible] = useState(false);
        const [showReturnModal, setShowReturnModal] = useState(false);
        const [showOverduePaymentModal, setShowOverduePaymentModal] = useState(false);
    const [lockerDoorOpened, setLockerDoorOpened] = useState(rental?.lockers?.[0]?.isDoorOpened);

    const rentalPurchasedPack = rental?.purchasedPack
    const formattedStartTime = formatDateTime(rental?.startTime);
    const formattedEndTime = formatDateTime(rental?.deadline);
    const RentalLockerId = rental?.lockers?.[0]?.id;
    const RentalLockerName = rental?.lockers?.[0]?.locker_id;
    const [startDate, startTime] = formattedStartTime.split(', ');
    const [endDate, endTime] = formattedEndTime.split(', ');

    const deadlineDate = parseISO(rental?.deadline);
    const deadlineTimeDifference = differenceInMinutes(new Date(), deadlineDate);

    const timeDifference = differenceInMinutes(deadlineDate, new Date());
    const isOvertime = timeDifference < 0;
    const isLessThan15Mins = timeDifference <= 0 && timeDifference > -15;
    const invoiceAvailable = rental?.invoice;
    const calculateTimeLeft = () => {
        const timeDifference = differenceInMinutes(parseISO(rental?.deadline), new Date());
        const isOvertime = timeDifference < 0;
        const isLessThanAnHour = Math.abs(timeDifference) < 60;

        return isOvertime
            ? `${formatTimeLeft(timeDifference, isLessThanAnHour, isOvertime)} overtime`
            : formatTimeLeft(timeDifference, isLessThanAnHour);
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const formattedTimeDifference = formatTimeDifference(deadlineTimeDifference);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 60000);

        return () => clearInterval(interval);
    }, [rental?.deadline]);

    useEffect(() => {
        if (isRentalActive && lockerUpdate) {
            if (lockerUpdate && lockerUpdate?.id === RentalLockerName.toLowerCase()) {
                setLockerDoorOpened(lockerUpdate.isDoorOpened);
            }
        }
    }, [lockerUpdate]);

    useEffect(() => {
        if (isRentalActive) {
            if (isRentalActive && lockerDoorOpened) {
                setDoorOpenModalVisible(true);
            } else {
                setDoorOpenModalVisible(false);
            }
        }
    }, [lockerDoorOpened, isRentalActive]);

    const circleColor = !isRentalActive ? "#186AB2" : isOvertime
        ? '#E45112'
        : isLessThan15Mins
            ? '#F59F39'
            : 'green';

    const textColor = isOvertime
        ? '#E45112'
        : isLessThan15Mins
            ? '#F59F39'
            : '#186AB2';
    const handleOpenStore = async () => {
        const token = Cookies.get('jwt');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rents/openOutsideDoor`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    };

    const handleUnlockLocker = async () => {
        const token = Cookies.get('jwt');

        if (!RentalLockerId || !token) {
                console.error("Locker ID or token is missing.");
                return;
            }

            const timeDifference = differenceInMinutes(new Date(), parseISO(rental?.startTime));
            const deadlineTimeDifference = differenceInMinutes(new Date(), parseISO(rental?.deadline));

            if (deadlineTimeDifference > 5) {
                setShowOverduePaymentModal(true);
            } else if (timeDifference > 10) {
                setShowReturnModal(true);
            } else {
                await unlockTheLocker(RentalLockerId);
            }
        };

        const calculateAdditionalFee = () => {
            const overtimeMinutes = Math.abs(differenceInMinutes(new Date(), deadlineDate));
            return Math.ceil(overtimeMinutes / 30) * 2;
        };
        const handleProceedToPayment = async () => {
            const token = Cookies.get('jwt');
            try {
                const rentId = rental.id;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rent/overtimefee/stripe-webhook-checkout/${rentId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    const errorResponse = await response.json();
                    console.error('Response Error:', errorResponse);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const {sessionId} = await response.json();
                if (!sessionId) {
                    throw new Error('Session ID not received from backend');
                }

                const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
                await stripe.redirectToCheckout({sessionId});
            } catch (error) {
                console.error('Error initiating checkout:', error);
            }
        };
        const terminateRent = async (rentId) => {
            const token = Cookies.get('jwt');

            if (!token) {
                throw new Error('No token found');
            }
            try {
                await unlockTheLocker(RentalLockerId);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rents/${rentId}/terminate`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                setIsRentalActive(false);
                setRentUpdated(true);
            } catch (error) {
                console.error('Error terminating rent:', error);
            }
        };

        const unlockTheLocker = async (lockerId) => {
            const token = Cookies.get('jwt');

            if (!lockerId || !token) {
                console.error("Locker ID or token is missing.");
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/lockers/${lockerId}/unlock`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    console.log("Locker successfully unlocked");
                } else {
                    const errorResponse = await response.json();
                    console.error('Failed to unlock locker:', errorResponse.error.message || 'An unknown error occurred');
                    alert(errorResponse.error.message || 'An unknown error occurred');
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };
    const handleDownloadInvoice = () => {
        const invoiceUrl = rental?.invoice?.url;
        if (!invoiceUrl) {
            console.error("Invoice URL not found.");
            return;
        }
        const tempLink = document.createElement('a');
        tempLink.href = invoiceUrl;
        tempLink.setAttribute('download', '');
        tempLink.style.display = 'none';

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    };

    /* const handleDownloadInvoice = async (rentId) => {
         const token = Cookies.get('jwt');

         try {
             const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rents/${rentId}/download-invoice`, {
                 headers: {
                     'Authorization': `Bearer ${token}`,
                 },
             });

             if (response.ok) {
                 const contentDisposition = response.headers.get('Content-Disposition');
                 let filename = "KeepItSafe_24/7_Invoice.pdf";
                 if (contentDisposition) {
                     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                     const matches = filenameRegex.exec(contentDisposition);
                     if (matches != null && matches[1]) {
                         filename = matches[1].replace(/['"]/g, '');
                     }
                 }
                 const blob = await response.blob();
                 const link = document.createElement('a');
                 link.href = window.URL.createObjectURL(blob);
                 link.download = filename;
                 link.click();
                 window.URL.revokeObjectURL(link.href);
             } else {
                 console.error('Failed to download invoice');
             }
         } catch (error) {
             console.error('There was a problem with the fetch operation:', error);
         }
     };*/

        return (
            <div className="rentalRow">
                <Modal
                    isOpen={isDoorOpenModalVisible}
                    onClose={() => setDoorOpenModalVisible(false)}>
                    <div className={"doorOpenedContainer"}>
                        <img src={"/imgs/locker.png"} alt={"Opened Locker"}/>
                        <p>Your locker door is opened!</p>
                    </div>
                </Modal>
                <OverduePaymentModal
                    showOverduePaymentModal={showOverduePaymentModal}
                    setShowOverduePaymentModal={setShowOverduePaymentModal}
                    rentalPurchasedPack={rentalPurchasedPack}
                    endTime={endTime}
                    formattedTimeDifference={formattedTimeDifference}
                    deadlineTimeDifference={deadlineTimeDifference}
                    calculateIntervals={calculateIntervals}
                    calculateAdditionalFee={calculateAdditionalFee}
                    handleProceedToPayment={handleProceedToPayment}
                />
                <ReturnModal
                    showReturnModal={showReturnModal}
                    setShowReturnModal={setShowReturnModal}
                    rental={rental}
                    terminateRent={terminateRent}
                    RentalLockerId={RentalLockerId}
                    unlockTheLocker={unlockTheLocker}
                />
                <div className={"rentalStateContainer"}>
                    <div className="statusIndicator" style={{backgroundColor: circleColor}}></div>
                    <div className="rentalStatusContainer">
                        <span className={"rentalStatus"}>{isRentalActive ? 'Active' : 'Closed'}</span>
                        {rental?.isActive ? (
                            <span className={"rentalStatusTime"} style={{color: textColor}}>
                            {timeLeft}
                        </span>
                        ) : ""}
                    </div>
                </div>
                <div className={"rentalPurchasedPack"}>
                    <span className={"rentedNumber"}>Locker nº{rental?.lockers?.[0]?.locker_id.replace(/[^\d]/g, '')}</span>
                    <span className={"rentedSize"}>{rental?.size}</span>
                    <span className={"rentedPack"}>{rentalPurchasedPack} pack</span>
                </div>
                <div>
                    <div>
                        started at {startDate}, <span id={"startTime"} style={{color: isRentalActive ? '#186AB2' : '#616161'}}>{startTime}</span>
                    </div>
                    <div>
                        {isRentalActive ? 'ends' : 'ended'} at {endDate}, <span id={"endTime"} style={{color: isRentalActive ? textColor : '#616161'}}>{endTime}</span>
                    </div>
                </div>
                {!isRentalActive && (
                    <div className={"rentalSummary"}>
                        <p className={"rentalPack"}>{rental?.purchasedPack} pack {rental?.size} - {rental?.amount}€</p>
                        {rental?.fee_amount ? <p>Overtime fee - {rental?.fee_amount} €</p> : ""}
                        <p className={"totalValueOfPack"}>Total - {(rental?.amount + rental?.fee_amount)} €</p>
                    </div>
                )}
                {!isRentalActive && invoiceAvailable && (
                    <div className={"invoiceNotAvailable"}>
                        <button
                            className={`b-button`}
                            onClick={() => handleDownloadInvoice()}>
                            <img src={"/imgs/download.svg"} alt={"download"}/>Download Invoice
                        </button>
                    </div>
                )}
                {isRentalActive && (
                    <>
                        <button className={"b-button"} onClick={handleOpenStore}><img src={"/imgs/door.svg"} alt={"door"}/>Open Store</button>
                        <button className={"b-button"} onClick={handleUnlockLocker}><img src={"/imgs/lock.svg"} alt={"lock"}/>Unlock Locker</button>
                    </>
                )}
            </div>
        );
    }
;


const RentalsPage = () => {
    const {user} = useUser();
    const [rentals, setRentals] = useState({active: [], closed: []});
    const [rentUpdated, setRentUpdated] = useState(false);
    const [error, setError] = useState(null);
    const [lockerUpdate, setLockerUpdate] = useState(null);

    const variants = {
        hidden: {opacity: 0, y: 50},
        visible: i => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.6,
                ease: "easeOut"
            },
        }),
    };

    useEffect(() => {
        if (user) {
            fetchRentals()
                .then(data => {
                    const activeRentals = data.filter(rental => rental?.isActive);
                    const closedRentals = data.filter(rental => !rental?.isActive);
                    setRentals({active: activeRentals, closed: closedRentals});
                    const activeLockerIds = activeRentals.map(rental => rental?.lockers?.[0]?.id);
                    if (activeLockerIds.length > 0) {
                        setupEventSource(activeLockerIds);
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                    setError(error.message);
                })
            setRentUpdated(false);
        }
    }, [user, rentUpdated]);


    const setupEventSource = (lockerIds) => {
        const token = Cookies.get('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/api/lockers/authenticate-for-sse`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({lockerIds})
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const sseToken = data.sseToken;

                const url = `${process.env.REACT_APP_API_URL}/api/lockers/subscribe?sseToken=${sseToken}`;
                const eventSource = new EventSource(url);
                eventSource.onmessage = (event) => {
                    const lockerData = JSON.parse(event.data);
                    setLockerUpdate(lockerData);
                };
                eventSource.onerror = (error) => {
                    console.error('EventSource error:', error);
                };
                eventSource.onclose = (event) => {
                    console.log('SSE connection closed:', event);
                };
                return () => {
                    eventSource.close();
                };
            })
            .catch(error => {
                console.error('Error fetching SSE token:', error);
            });
    };

    if (!user) {
        return <Navigate to="/" replace/>;
    }

    if (error) {
        return <div style={{textAlign: "center", maxWidth: "550px", margin: "auto"}}>We're sorry for the inconvenience. There seems to be an issue at our end. Please try refreshing the page. If the problem persists, rest assured that we are working on resolving it and suggest trying again shortly. For immediate assistance or if you have any questions, feel free to contact us at keepitsafe247help@gmail.com or via WhatsApp. Your understanding and patience are greatly appreciated.</div>;
    }

    return (
        <div className={"rentalsPage"}>
            <div className={"headerContainer"}>
                <h1>My Lockers</h1>
                <p>Manage all your Keep It Safe bookings</p>
            </div>
            <Tabs>

                <Tab label="Active Bookings">
                    {rentals.active.length > 0 ? (
                        rentals.active.map((rental, index) => (
                            <motion.div
                                key={rental?.id}
                                custom={index}
                                variants={variants}
                                initial="hidden"
                                animate="visible"
                            >
                                <RentalRow setRentUpdated={setRentUpdated} rental={rental} lockerUpdate={lockerUpdate}/>
                            </motion.div>
                        ))
                    ) : (
                        <p style={{textAlign: "center"}}>No active bookings available.</p>
                    )}
                </Tab>
                <Tab label="History">
                    {rentals.closed.length > 0 ? (
                        rentals.closed.map((rental, index) => (
                            <motion.div
                                key={rental?.id}
                                custom={index}
                                variants={variants}
                                initial="hidden"
                                animate="visible"
                            >
                                <RentalRow setRentUpdated={setRentUpdated} key={rental?.id} rental={rental}/>
                            </motion.div>
                        ))
                    ) : (
                        <p style={{textAlign: "center"}}>No past bookings available.</p>
                    )}
                </Tab>
            </Tabs>

            <div className={"needAHandContainer"}>
                <div className="textContainer">
                    <h1>Need a hand?</h1>
                    <p>If you have any doubts about our lockers or the way they should be used, please contact us via WhatsApp, we will get to you as soon as possible.</p>
                    <a className={"contactUsLink"} href="https://wa.me/935899178">
                        <button className="c-button contactUsButton">
                            <img src="/imgs/brands/whatsapp.svg" alt="WhatsApp"/>Contact us via WhatsApp
                        </button>
                    </a>
                </div>
                <img className={"safeTravels"} src="/imgs/woman_travel.svg" alt="Keep your things safe with us"/>
            </div>
            <Footer/>
        </div>
    );
};


export default RentalsPage;
