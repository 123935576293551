import React, {createContext, useState, useContext} from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({children}) => {
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
    const [authSuccessCallback, setAuthSuccessCallback] = useState(null);
    const [showPasswordRetrievalModal, setShowPasswordRetrievalModal] = useState(false);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);

    const [resetCode, setResetCode] = useState(null);
    const openPasswordResetModal = (code) => {
        setResetCode(code);
        setShowPasswordResetModal(true);
    };
    const closePasswordResetModal = () => {
        setResetCode(null);
        setShowPasswordResetModal(false);
    };


    return (
        <ModalContext.Provider value={{
            showRegisterModal,
            setShowRegisterModal,
            showLoginModal,
            setShowLoginModal,
            showPasswordRetrievalModal,
            setShowPasswordRetrievalModal,
            authSuccessCallback,
            setAuthSuccessCallback,
            showPasswordResetModal,
            openPasswordResetModal,
            closePasswordResetModal,
            showSummaryModal,
            setShowSummaryModal,
            showSuccessModal,
            setShowSuccessModal,
            showDeletionModal,
            setShowDeletionModal,
            resetCode,
            setResetCode
        }}>
            {children}
        </ModalContext.Provider>
    );
};
