import React from 'react';
import Modal from "../Modal/Modal";

function ReturnModal({
                         showReturnModal,
                         setShowReturnModal,
                         rental,
                         terminateRent,
                         RentalLockerId,
                         unlockTheLocker
                     }) {
    return (
        <Modal isOpen={showReturnModal} onClose={() => setShowReturnModal(false)}>
            <div className="returnModal">
                <h1>Will you be returning?</h1>
                <div className="returnContainer">
                    <div className="returnImageContainer">
                        <img src={"/imgs/closingLockers.svg"} alt={"Lockers"}/>
                    </div>
                    <div className={"returnSubContainer"}>
                        <p className={"beforeYouGo"}>
                            Before you open your locker, please let us know if you'll be returning or if you're all done and ready to finish your booking.
                        </p>
                        <p>
                            If you plan on returning to the locker, we'll keep your booking open. If you're finished, we'll close it out. This helps us manage our bookings more efficiently.
                        </p>
                        <div className={"returnButtonsContainer"}>
                            <button className={"c-button"} onClick={() => terminateRent(rental.id)}>Finish Booking</button>
                            <button className={"c-button c-alternative"} onClick={() => {
                                unlockTheLocker(RentalLockerId);
                                setShowReturnModal(false);
                            }}>I'll Be Returning
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ReturnModal;
