import React, {useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import {formatEndTime} from '../../services/timeFormat';
import {motion} from "framer-motion";
import Lottie from "lottie-react";

const AnimationComponent = () => {
    const [animationData, setAnimationData] = useState(null);

    useEffect(() => {
        fetch('/imgs/greenCheck.json')
            .then(response => response.json())
            .then(data => {
                setAnimationData(data);
            })
            .catch(error => console.log(error));
    }, []);

    if (!animationData) return null;


    return (
        <Lottie className={'checkAnimation'}
                animationData={animationData}
                loop={false}
        />
    );
};
const SuccessModal = ({showSuccessModal, setShowSuccessModal, lockerData, transactionDetails}) => {
    const locker = lockerData.find(l => l.size === transactionDetails?.lockerSize);

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        removeQueryParameters();
    };
    const removeQueryParameters = () => {
        const url = new URL(window.location);
        url.searchParams.delete('transaction');
        url.searchParams.delete('sessionId');
        window.history.pushState({}, '', url);
    };

    function LockerLinkButton({closeSuccessModal}) {
        const handleLockerLinkClick = () => {
            closeSuccessModal();
            setTimeout(() => {
                window.location.href = '/rentals';
            }, 10);
        };

        return (
            <button className="c-button largeButton" onClick={handleLockerLinkClick}>
                My Lockers
            </button>
        );
    }
    return (
        <Modal isOpen={showSuccessModal} onClose={closeSuccessModal}>
            <div className="summaryContent">
                <h1>Booking confirmed</h1>
                {transactionDetails && (
                    <>
                        <div className={"summaryInfoContainer"}>
                            <div className={"summaryLockerImageContainer"}>
                                <div className="image-animation-wrapper">
                                    <img src={locker?.imageSrc} alt={locker ? locker?.altText : 'Locker'}/>
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{duration: 1}}
                                        className="animation-container">
                                        <AnimationComponent/>
                                    </motion.div>
                                </div>
                            </div>
                            <div className={"summaryInfo"}>
                                <span className={"summarySize"}>{transactionDetails?.lockerSize}</span>
                                <p className={"summaryDimensions"}> {locker ? locker?.dimensions : 'Not available'}</p>
                                <div className={"summaryDurationContainer"}>
                                    <p className={"summaryDuration"}>Pack {transactionDetails?.duration}</p>
                                    <p>starting now</p>
                                    <p>ends at {formatEndTime(transactionDetails?.endTime)}</p>
                                    <p className={"totalValue"}>Total: {transactionDetails?.total}€</p>
                                </div>
                                <p className={"manageLockersWarning"}>You can manage your reservations in “My Lockers” page. There, you’ll be able to open you locker.</p>
                                <LockerLinkButton closeSuccessModal={closeSuccessModal}/>
                            </div>
                        </div>
                    </>)}
            </div>
        </Modal>
    );
};

export default SuccessModal;