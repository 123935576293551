import React from 'react';
import Modal from "../Modal/Modal";

function OverduePaymentModal({
                                 showOverduePaymentModal,
                                 setShowOverduePaymentModal,
                                 rentalPurchasedPack,
                                 endTime,
                                 formattedTimeDifference,
                                 deadlineTimeDifference,
                                 calculateIntervals,
                                 calculateAdditionalFee,
                                 handleProceedToPayment
                             }) {
    return (
        <Modal isOpen={showOverduePaymentModal} onClose={() => setShowOverduePaymentModal(false)}>
            <div className="overduePaymentModalContent">
                <h1>Before you open your locker...</h1>
                <div className={"overduePaymentContainer"}>
                    <div className={"overduePaymentImageContainer"}>
                        <img src={"/imgs/overduePayment.svg"} alt={"Overdue Payment"}/>
                    </div>
                    <div className={"overduePaymentSubContainer"}>
                        <p className={"overdueWarning"}>We've noticed that your {rentalPurchasedPack} package expired at {endTime}, which was {formattedTimeDifference} ago.</p>
                        <p>In line with our policy, there's an overtime fee of 2€ per 30 minutes.</p>
                        <div className={"overtimeFeeCalculate"}>
                            <p>Overtime fee</p>
                            <span>{calculateIntervals(deadlineTimeDifference)}</span>
                        </div>
                        <p className={"totalValue"}>Total: €{calculateAdditionalFee()}</p>
                        <div className={"overtimeFeePaymentContainer"}>
                            <button className={"c-button"} onClick={handleProceedToPayment}>Proceed to Payment</button>
                            <div className={"paymentMethodsContainer"}>
                                <img src={"/imgs/visa.svg"} alt={"Visa"}/>
                                <img src={"/imgs/mastercard.svg"} alt={"Mastercard"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default OverduePaymentModal;