import {Navigate} from "react-router-dom";
import {useUser} from "./UserContext";

const ProtectedRoute = ({children}) => {
    const {user, isLoading} = useUser();

    if (isLoading) {
        return <div style={{width: "100%", textAlign: "center"}}>Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/"/>;
    }

    return children;
};

export default ProtectedRoute;
