import React, {useState} from 'react';
import './Footer.scss';
import Modal from '../Modal/Modal';
import PrivacyPolicy from '../Legal/PrivacyPolicy';
import TermsConditions from '../Legal/TermsConditions';

const Footer = () => {
    const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
    const [isTermsConditionsOpen, setIsTermsConditionsOpen] = useState(false);

    return (
        <footer className="footerContainer">
            <div className="footerColumns">
                <div className="footerLogo">
                    <img src="/imgs/logo.svg" alt="KeepItSafe Logo"/>
                </div>
                <div className="footerColumn">
                    <h4>Learn More</h4>
                    <ul>
                        <li>
                            <button className={"footerButton"} onClick={() => setIsPrivacyPolicyOpen(true)}>Privacy Policy</button>
                        </li>
                        <li>
                            <button className={"footerButton"} onClick={() => setIsTermsConditionsOpen(true)}>Terms & Conditions</button>
                        </li>
                    </ul>
                </div>
                <div className="footerColumn">
                    <h4>Tickets & Booking</h4>
                    <ul>
                        <li>
                            <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">
                                <button className="footerButton">Complaints Book</button>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footerColumn">
                    <h4>Contact Us</h4>
                    <ul>
                        <li>+351 935899178</li>
                    </ul>
                </div>
                <div className="footerColumn social">
                    <h4>Social</h4>
                    <div className="socialContainer">
                        <a href="https://www.facebook.com/profile.php?id=61555155595776" target="_blank" rel="noopener noreferrer">
                            <img src="/imgs/social/facebook.svg" alt="facebook"/>
                        </a>
                        <a href="https://www.instagram.com/keepitsafe24_7?igsh=MTloMnU5aWo5NnNrcA==" target="_blank" rel="noopener noreferrer">
                            <img src="/imgs/social/instagram.svg" alt="instagram"/>
                        </a>
                        <a href="https://www.linkedin.com/company/keep-it-safe-24-7/" target="_blank" rel="noopener noreferrer">
                            <img src={"/imgs/social/linkedin.svg"} alt={"linkedin"}/>
                        </a>
                    </div>
                </div>
            </div>
            <p>© 2023 Keep It Safe | All Rights Reserved</p>

            <Modal isOpen={isPrivacyPolicyOpen} onClose={() => setIsPrivacyPolicyOpen(false)}>
                <PrivacyPolicy/>
            </Modal>
            <Modal isOpen={isTermsConditionsOpen} onClose={() => setIsTermsConditionsOpen(false)}>
                <TermsConditions/>
            </Modal>
        </footer>
    );
};

export default Footer;
