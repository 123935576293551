import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Navbar from "./components/Navbar/Navbar";
import HomePage from "./components/Homepage/Homepage";
import {UserProvider} from "./components/Auth/UserContext";
import {ModalProvider} from "./components/Auth/ModalContext";
import RentalsPage from "./components/Rentals/RentalsPage";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import CookieConsentComponent from "./components/CookieConsent/CookieConsent";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {

    return (
        <UserProvider>
            <ModalProvider>
                <Router>
                    <Elements stripe={stripePromise}>
                        <div className="App">
                            <Navbar/>
                            <CookieConsentComponent/>
                            <Routes>
                                <Route path="/" element={<HomePage/>}/>
                                <Route path="/rentals" element={<ProtectedRoute><RentalsPage/></ProtectedRoute>}/>
                            </Routes>
                        </div>
                    </Elements>
                </Router>
            </ModalProvider>
        </UserProvider>
    );
}

export default App;