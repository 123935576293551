import "./Legal.scss";
const PrivacyPolicy = () => (
    <div className={"legalContainer"}>
        <h1>PRIVACY POLICY</h1>
        <p>The General Data Protection Regulation (GPDR) – EU Regulation
            2016/679 – has been applicable since 25 May 2018 in all Member States of
            the European Union and concerns the processing of personal data of natural
            persons and the free movement of such data. <br/>
            GONÇALVES & PESTANA, LDA. values and attributes the utmost
            importance to your personal data. Accordingly, we guarantee that they are
            processed with total privacy, security, confidentiality and transparency.<br/>
            The processing of your personal data allows us, among other purposes,
            to develop and directly commercialise the products and services that we
            believe are best suited to the profile and needs of each Client.<br/>
            This Privacy Policy outlines how GONÇALVES & PESTANA, LDA.
            uses the personal data of its clients and potential clients.</p>
        <ol>
            <li><h2>IDENTIFICATION OF THE IDENTITY RESPONSIBLE FOR
                THE PROCESSING OF PERSONAL </h2>
            </li>
            <ul>
                <li>Company: GONÇALVES & PESTANA, LDA.</li>
                <li>Registered Office: Rua da Rochinha, nr. 49, 9060-280 FUNCHAL</li>
                <li>Portuguese Tax Number (NIPC): 517440644</li>
                <li>Data Protection Officer: keepitsafe247@hotmail.com</li>
            </ul>
            <p>
                According with the current legislation, the data subject must be
                informed that the personal data provided during access, browsing and use of
                the website www.keepitsafe247.pt and its subdomains, as well as all data
                provided during their relationship with GONÇALVES & PESTANA, LDA.
                will be processed by GONÇALVES & PESTANA, LDA. <br/>
                In the event of any queries or requests relating to the processing of data,
                the data subject may contact our Data Protection Officer at any time by
                writing to GONÇALVES & PESTANA, LDA. at the following address: Rua
                da Rochinha, nr. 49, 9060-280 FUNCHAL, or by e-mail to
                keepitsafe247@hotmail.com.
            </p>
            <li>
                <h2>HOW WE COLLECT YOUR PERSONAL DATA, THE PURPOSES FOR WHICH IT IS PROCESSED AND THE LEGITIMACY OF ITS PROCESSING</h2>
            </li>
            <p>GONÇALVES & PESTANA, LDA. may monitor and record
                communications with you (such as phone messages/calls – through
                Whatsapp or not – and emails). This may happen for several reasons, such
                as to check the quality of our customer service, to prevent fraud or to make
                sure GONÇALVES & PESTANA, LDA. Is complying with legal
                requirements.<br/>
                Your data will be processed by GONÇALVES & PESTANA, LDA.
                for the following purposes, depending on when you provide them to us:</p>
            <h3>2.1. BROWSING OUR WEBSITE</h3>
            <p>When Users use GONÇALVES & PESTANA, LDA.’s website, the
                following personal data is automatically recorded by GONÇALVES &
                PESTANA’s device and temporarily stored as log files without their
                intervention:</p>
            <ul>
                <li>IP address used by the User’s device;</li>
                <li>Date and time of access;</li>
                <li>Name and URL of the file being accessed;</li>
                <li>Browser type and operating system of the User’s device;</li>
                <li>Website/application from which access was made (reference URL);</li>
                <li>Name of the access provider</li>
            </ul>
            <p>
                The purposes of this procedure are, on the one hand, to carry out
                operations that focus on internal solutions, data analysis, research,
                development and improvement of the service, as well as control of the
                security and stability of the systems, and this processing is justified by the
                legitimate interest of GONÇALVES & PESTANA, LDA.. <br/>
                On the other hand, the purpose of recording the aforementioned
                information is to fulfil the obligations laid down by law, and this processing
                is justified by the fulfilment of GONÇALVES & PESTANA, LDA.’s legal
                obligations.<br/>
                GONÇALVES & PESTANA, LDA.’s website also uses cookies (see
                “Use of cookies”) and collects IP addresses (which means a number that can
                uniquely identify a specific computer or other device on the Internet).
            </p>
            <h3>2.2. CUSTOMER REGISTRATION</h3>
            <p>User’s personal data will be collected by GONÇALVES &
                PESTANA, LDA. through the customer registration form during future
                commercial transactions that may be carried out, as well as during actions to
                update your data, for the purposes of:</p>
            <ul>
                <li><p>Management, maintenance and administration of the contractual
                    relationship and, as the case may be, to satisfactorily process the
                    contracted service. The processing of User’s data for this purpose is
                    based on the provisions of Art. 6º, nr. 1, b) of the GDPR.</p></li>
                <li><p>Analyzing and expanding the contractual relationship with the
                    Customer. As a result, market research will be carried out, as well as
                    analyzing and segmenting profiles (e.g. turnover, repeat purchases or
                    purchase dates), using GONÇALVES & PESTANA, LDA.’s own
                    databases created through the Customer’s contractual relationship, in
                    order to improve said relationship. The processing of User’s data for
                    this purpose is based on the provisions of Art. 6º, nr. 1, f) of the GDPR.</p></li>
                <li><p>Compliance with the legal obligations of GONÇALVES &
                    PESTANA, LDA. arising from the contractual relationship with the
                    Client. The processing of User’s data for this purpose is based on the
                    provisions of Art. 6º, nr. 1, c) of the GDPR.
                    If the data subject provides data from third parties, they must declare
                    that they have their consent, committing to provide them with the
                    information contained in this clause, exempting GONÇALVES &
                    PESTANA, LDA. from any responsibility.</p></li>
            </ul>
            <h3>2.3. MANAGEMENT OF QUERIES, SUGGESTIONS AND/OR
                COMPLAINTS</h3>
            <p>Users can use the email address keepitsafe247@gmail.com, as well as
                make calls or messages to phone number (+351) ... , for clarifications related
                to customer support. The processing of your data for this purpose is based
                on the provisions of Art. 6º, nr. 1, b) of the GDPR</p>
            <p>The data provided by the User when making any enquiry, suggestion
                and/or complaint may be transferred to related companies for the purpose of
                managing them. This transfer will be necessary in some cases in order to
                process the User’s enquiry, suggestion or complaint.</p>
            <p>GONÇALVES & PESTANA, LDA. may also collect personal
                information when the User contacts, sends feedback, or makes complaints</p>
            <h3>2.4. PERSONAL INFORMATION ABOUT OTHER
                INDIVIDUALS</h3>
            <p>If the User gives GONÇALVES & PESTANA, LDA. information on
                behalf of someone else, the User confirms that the other person has appointed
                them to act on his/her behalf and has agreed that then can:</p>
            <ul>
                <li><p>Give consent on his/her behalf to the processing of his/her personal
                    data;</p></li>
                <li><p>Receive on his/her behalf any data protection notices; and</p></li>
                <li><p>Give consent to the transfer of his/her personal data abroad.</p></li>
            </ul>
            <li><h2>MANDATORY PROVISION OF DATA</h2></li>
            <p>The personal data requested in the mandatory forms, with the
                exception of those in relation to which it is expressly stated otherwise, in
                such a way that refusal to provide them will imply the impossibility of
                achieving the aforementioned purposes.</p>
            <p>In the event that the data holder provides data from third parties, they
                must declare that they have their consent, undertaking to provide them with
                the information contained in this clause, exempting GONÇALVES &
                PESTANA, LDA. from any responsibility for the information</p>
            <li><h2>WHICH PERSONAL DATA WILL BE PROCESSED</h2></li>
            <p>GONÇALVES & PESTANA, LDA. will process the following
                categories of data:</p>
            <span style={{textDecoration: "underline"}}>Costumer registration:</span>
            <ul>
                <li><p>Identification data: First name, Surname(s) (name and surname of
                    authorized buyers); Tax Identification Number; </p></li>
                <li><p>Contact details: Telephone number; E-mail address</p></li>
            </ul>
            <span style={{textDecoration: "underline"}}>Contact request form or customer service form:</span>
            <ul>
                <li><p>Identification data: First name; Surname(s);</p></li>
                <li><p>Contact details: Phone number; E-mail address;</p></li>
                <li><p>Other data provided through the formulation of an enquiry, complaint
                    and/or claim</p></li>
            </ul>
            <li><h2>STORAGE PERIODS</h2></li>
            <p>Customer data will be kept for the periods mentioned above:</p>
            <ul>
                <li><p>For the purposes of managing, maintaining and managing the
                    contractual relationship concluded, the Customer’s data will be kept
                    for the duration of the existing contractual relationship and thereafter
                    until any liability arising from the contractual relationship has expired;</p></li>
                <li><p>For the purposes of analyzing and extending the contractual
                    relationship, the Customer’s data will be kept for the duration of the
                    contractual relationship or until the Customer objects to the
                    processing;</p></li>
                <li><p>For the purposes of complying with legal obligations arising from the
                    contractual relationship, the Customer’s data will be kept for the
                    periods provided for by law;</p></li>
                <li><p>For the purposes of managing your enquiry, suggestion, complaint
                    and/or claim, the Customer’s data will be kept for this purpose for the
                    entire duration of the management of your enquiry and thereafter until
                    any liability arising from the consultation has been prescribed.</p></li>
            </ul>
            <li><h2>DATA RECIPIENTS AND INTERNATIONAL TRANSFERS</h2></li>
            <ul>
                <li><p>The Tax Authority, for the purposes of complying with
                    GONÇALVES & PESTANA, LDA.’s tax obligations, as well as any
                    other public administration entity with the power to access the data;</p></li>
                <li><p>Judges and Courts in the cases provided for by law</p></li>
            </ul>
            <p>According with art. 28º of the GDPR, and in order to fulfil the
                aforementioned purposes, GONÇALVES & PESTANA, LDA. uses the
                following suppliers as subcontractors, among others:</p>
            <ul>
                <li><p>Data hosting service providers;</p></li>
                <li><p>Internet development and maintenance service providers;</p></li>
                <li><p>Technology service providers;</p></li>
                <li><p>Providers of printing services;</p></li>
                <li><p>Providers of support services for sending e-mail and SMS messages;</p></li>
                <li><p>Telephone contact service providers;</p></li>
                <li><p>Providers of marketing and advertising printing services;</p></li>
            </ul>
            <li><h2>PAYMENT INFORMATION</h2></li>
            <p>GONÇALVES & PESTANA, LDA. does not collect or hold User’s
                payment information</p>
            <p>All payments are handled by our third party payment processor, Stripe.
                Stripe only store what information they are required to store by law. No
                additional information is stored unless your express permission is given (e.g.
                “Remember Me” function at checkout). This information is stored securely
                in a manner that is not accessible. Please check their privacy policies for
                further information.</p>
            <li><h2>USER LIABILITY</h2></li>
            <p>The User hereby:</p>
            <ul>
                <li><p>Guarantees that they are over 18 years of age and that the data they
                    provide to GONÇALVES & PESTANA, LDA. is true, accurate,
                    complete and up-to-date. To this end, the User is responsible for the
                    veracity of all the data they provide and must keep the information
                    provided duly updated so that it corresponds to their real situation;</p></li>
                <li><p>Guarantees that they fulfil the necessary requirements to become a
                    GONÇALVES & PESTANA, LDA. User and Client;</p></li>
                <li><p>Guarantees that they have informed the third party owners’ of the data
                    they make available, if they make them available and of the provisions
                    of this document. The User also warrants that they have obtained the
                    authorization of these third parties to make their data available to
                    GONÇALVES & PESTANA, LDA. for the aforementioned purposes;</p></li>
            </ul>
            <p>The User will be responsible for any false or inaccurate information
                you transmit via the website and for any direct or indirect damage this may
                cause to GONÇALVES & PESTANA, LDA. or to third parties.</p>
            <li><h2>RIGHTS OF THE DATA HOLDER</h2></li>
            <p>The data holder has the following rights:</p>
            <ul>
                <li><p>Right of access: to receive information about the processing of their
                    data, as well as a copy of the data – Art. 15º of the GDPR;</p></li>
                <li><p>Right to rectification: rectify inaccurate or incomplete data – art. 16º
                    of the GDPR;</p></li>
                <li><p>Right to erasure: request the erasure of your data and, if it has been
                    provided to third parties, the right for GONÇALVES & PESTANA,
                    LDA. to inform them of this request – Art. 17º of the GDPR;</p></li>
                <li><p>Right to restriction of processing: obtain restriction of the processing
                    of your data – art. 18º of the GDPR;</p></li>
                <li><p>Right to portability: Receive your personal data in a structured,
                    commonly used and readable format and transmit it to another
                    controller – Art. 20º of the GDPR;</p></li>
                <li><p>Right to object: to object to processing – Art. 21º of the GDPR;</p></li>
                <li><p>Obtain human intervention, express your point of view and contest
                    automated decisions made by GONÇALVES & PESTANA, LDA;</p></li>
                <li><p>Right to withdraw consent: withdraw consent previously given, when
                    the purpose is based on the User's consent – art. 7º of the GDPR;</p></li>
                <li><p>Right to lodge a complaint with the competent supervisory authority
                    if you consider that GONÇALVES & PESTANA, LDA. has violated
                    any provision of the GDPR – art. 77º of the GDPR.</p></li>
            </ul>
            <p>In order to exercise the aforementioned rights, the data holder must
                send a written communication to the registered office of GONÇALVES &
                PESTANA, LDA. located at Rua da Rochinha nr. 49, 9060-280 FUNCHAL,
                proving their identity and indicating “Data Protection” as the Subject, or to
                the e-mail address keepitsafe247@gmail.com.</p>
            <li><h2>COOKIES POLICY</h2></li>
            <p>GONÇALVES & PESTANA, LDA. uses their own cookies to analyze
                how Users use the website, in order to improve our services and create a User
                profile. For more information, please consult our Cookie Policy</p>
            <li><h2>SECURITY MEASURES</h2></li>
            <p>GONÇALVES & PESTANA, LDA. will always process the User’s
                data with the required confidentially and will respect the mandatory duty of
                secrecy, in accordance with the provisions of the applicable regulations,
                adopting, for this purpose, the technical and organizational measures
                necessary to guarantee the security of the User’s data and prevent its
                unauthorized alteration, loss, processing or access, taking into account the
                state of the technology, the nature of the data stored and the risks to which
                they are exposed.</p>
            <li><h2>CHANGE OF THE TERMS</h2></li>
            <p>Due to possible changes in applicable legislation, it may become
                necessary to modify or update the data protection policies. GONÇALVES &
                PESTANA, LDA. will then inform the data subject of these changes and may
                ask for their consent if necessary.</p>
            <li><h2>HOW YOU CAN CHANGE OR WITHDRAW YOUR
                CONSENT</h2></li>
            <p>If the User is dissatisfied with our use of their personal data or with
                GONÇALVES & PESTANA, LDA.’s response after exercising any of these
                rights, they have the right to lodge a complaint with the supervisory
                authority:</p>
            <p>
                Comissão Nacional de Proteção de Dados – CNPD<br/>
                Rua de São Bento, n.º 148, 3º,<br/>
                1200-821 Lisboa<br/>
                geral@cnpd.pt<br/>
                Last updated: ...<br/>
                Gonçalves & Pestana, Lda..<br/>
                All rights reserved.<br/>
            </p>
        </ol>
    </div>
);

export default PrivacyPolicy;
