import React, {useState} from 'react';
import * as yup from 'yup';

const passwordResetSchema = yup.object().shape({
    newPassword: yup.string()
        .required('New password is required')
        .min(8, 'Password must be at least 8 characters long'),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Password confirmation is required'),
});

const PasswordReset = ({onClose, code, onResetSuccess}) => {
    const [passwordData, setPasswordData] = useState({newPassword: '', passwordConfirmation: ''});
    const [errors, setErrors] = useState({});
    const [resetError, setResetError] = useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setPasswordData({...passwordData, [name]: value});
        if (errors[name]) {
            setErrors({...errors, [name]: ''});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setResetError('');

        try {
            await passwordResetSchema.validate(passwordData, {abortEarly: false});

            // Extract the code from the URL
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    code: code,
                    password: passwordData.newPassword,
                    passwordConfirmation: passwordData.passwordConfirmation,
                }),
            });

            if (response.ok) {
                onClose();
                onResetSuccess();
            } else {
                setResetError("Failed to reset password. Please try again.");
            }
        } catch (yupError) {
            if (yupError && yupError.inner) {
                const newErrors = yupError.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {});
                setErrors(newErrors);
            } else {
                console.error('An unexpected error occurred:', yupError);
                setResetError('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className={"formContainer"}>
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit}>
                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="password"
                            name="newPassword"
                            value={passwordData.newPassword}
                            onChange={handleChange}
                            placeholder={"New Password"}
                        />
                    </div>
                    {errors.newPassword && <p className="errorMessage">{errors.newPassword}</p>}
                </div>

                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="password"
                            name="passwordConfirmation"
                            value={passwordData.passwordConfirmation}
                            onChange={handleChange}
                            placeholder={"Confirm New Password"}
                        />
                    </div>
                    {errors.passwordConfirmation && <p className="errorMessage">{errors.passwordConfirmation}</p>}
                </div>

                {resetError && <p className="errorMessage">{resetError}</p>}

                <button className={"c-button formButton"} type="submit">Reset Password</button>
            </form>
        </div>
    );
};

export default PasswordReset;
