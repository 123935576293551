import "./Legal.scss";

const TermsConditions = () => (
    <div className={"legalContainer"}>
        <h1>TERMS & CONDITIONS</h1>
        <h2>1. GENERAL</h2>
        <p>
            Keep it Safe 24/7 lockers were designed for stand-alone use, according to the
            operating instructions displayed both on our website and at the front door of the shop.
            Keep it Safe 24/7 shop is located at Travessa do Freitas n°9B, 9000-042 FUNCHAL and is
            open 24 hours a day, 7 days a week.
        </p>

        <h2>2. MAXIMUM USAGE TIME</h2>
        <p>
            Maximum usage time: 24h renewable hours. If the user fails to collect their belongings 5 minutes
            after the end of the time slot they have chosen, a fixed fee of 2,00 € will be charged for every
            additional half-hour beyond your reserved period. Items not collected in 30 days after deposit may be
            removed and disposed and Keep it Safe cannot be held responsible for these objects. For a larger usage
            time please contact us previously.
        </p>

        <h2>3. FORBIDDEN ARTICLES AND USES</h2>
        <ul>
            <li>People;</li>
            <li>Living animals;</li>
            <li>Perishable food items or spoiled food;</li>
            <li>Any kind of waste, dejections, baby nappies or use the lockers as a changing table;</li>
            <li>Liquids;</li>
            <li>Electronic cigarettes;</li>
            <li>Batteries;</li>
            <li>Stolen goods, substance(s) or other contraband deemed illegal or unlawful under the Portuguese legislation;</li>
            <li>Illegal substances (e.g., drugs);</li>
            <li>Hazardous substances (e.g., explosives, fuel, pesticides, radioactive materials and other toxic chemicals, pollutants, flammable or acidic items);</li>
            <li>Weapons, firearms or ammunition;</li>
            <li>Travel documents (e.g., passports, boarding passes);</li>
            <li>Jewelry, watches and items made of precious metals;</li>
            <li>Cash or tradeable assets that hold monetary value (e.g., share certificates, stocks, bonds);</li>
            <li>All articles whose deposit can, in any way, damage the lockers.</li>
        </ul>
        <p>Smoking is strictly forbidden inside the shop.</p>


        <h2>4. HOW OUR LOCKERS WORK</h2>
        <p>
            The user must access the website ... via portable device and register with their name, email address, and phone number.
            On the website, the user selects the number of lockers required, the size of the locker(s), and the time slot desired. They then proceed to make the payment.
        </p>
        <p>
            Once the payment has been made, the user is redirected to another webpage, where they press the digital button. At this point, the front door of the shop will unlock.
            Users should find their allocated locker(s) and open it/them by pressing a button that will pop up in the same webpage they used to unlock the front door of the shop.
        </p>
        <p>
            Finally, the user places their belongings inside the locker, closes it manually and that's it. The user can enter the shop as many times as they like within the time slot they have chosen.
            The user must collect their belongings by the end of the chosen time slot. Only the user who booked the lockers is allowed to collect the items deposited.
        </p>
        <p>
            Users can also renew their usage time depending on the availability of the lockers. Each Keep it Safe 24/7 locker can withstand up to 60 kg.
        </p>

        <h2>5. KEEP IT SAFE LOCKERS’ CAPACITY</h2>
        <table>
            <thead>
            <tr>
                <th>
                    M<br/>
                    <span>50cmx30cmx85cm</span>
                </th>
                <th>
                    L
                    <br/>
                    <span>50cmx50cmx85cm</span>

                </th>
                <th>
                    XL
                    <br/>
                    <span>50cmx60cmx85cm</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1 carry-on suitcase<br/>+<br/>1 personal item</td>
                <td>2 carry-on suitcases<br/>+<br/>3 personal items<br/>OR<br/>1 hold luggage<br/>+<br/>2 personal items</td>
                <td>4 carry-on suitcases<br/>OR<br/>1 hold luggage<br/>+<br/>3 personal items</td>
            </tr>
            </tbody>
        </table>
        <p>
            Each Keep it Safe 24/7 locker can withstand up to 60 kg.
        </p>
        <p>
            If the locker does not fit as many items as you intended, you should
            book an extra locker. Misuse can break the lockers’ door and Keep it Safe
            24/7 will charge you up to 250,00 € for repair.
        </p>

        <h2>6. Price *</h2>
        <p>
            Our prices depend both on the dimension of the chosen locker and the
            time slot required:
        </p>
        <table>
            <thead>
            <tr>
                <th></th>
                <th>
                    M<br/>
                    <span>50cmx30cmx85cm</span>
                </th>
                <th>
                    L
                    <br/>
                    <span>50cmx50cmx85cm</span>

                </th>
                <th>
                    XL
                    <br/>
                    <span>50cmx60cmx85cm</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>UP TO 3H</td>
                <td>4,90 €</td>
                <td>9,90 €</td>
                <td>11,90 €</td>
            </tr>
            <tr>
                <td>UP TO 5H</td>
                <td>7,90 €</td>
                <td>12,90 €</td>
                <td>16,90 €</td>
            </tr>
            <tr>
                <td>UP TO 24H</td>
                <td>10,90 €</td>
                <td>15,90 €</td>
                <td>19,90 €</td>
            </tr>
            </tbody>
        </table>
        <p>
            <strong>*</strong> If the user fails to collect their belongings 5 minutes after their time slot has ended, a rate of 2,00 € is due every half-hour.
            Users have 5 minutes after their time slot has ended to collect their belongings. After that time, their order will be automatically renewed for
            half an hour, for a rate of 2,00 € per renewal. All additional periods of use will be charged at the opening of the locker
            and charged to the indicated payment method.
        </p>
        <p>
            Payments can only be made by debit or credit card – VISA, MasterCard, Maestro, American Express, UnionPay, Apple Pay, Google Pay, Diners
            Club, Discover, and JCB – via the Stripe or similar platform, to which the user will be redirected when making the payment.
            All invoices will be sent via email.
        </p>

        <h2>7. PREMIUM SERVICE</h2>
        <p>
            If users want their bags collected and delivered, Keep it Safe 24/7 offers a premium service upon availability, where our team collects users’
            belongings in the arranged location.
        </p>
        <p>
            The premium service price depends on the distance covered by the employees to collect and deliver users’ belongings.
        </p>
        <p>
            Payment method: cash only.
        </p>
        <h2>8. LIABILITY</h2>
        <p>
            Users are entirely responsible for the items deposited in the lockers. If the locker does not fit as many items as you intended, you should
            book an extra locker. Misuse can break the lockers’ door and Keep it Safe 24/7 will charge you up to 250,00 € for repair.
        </p>
        <p>
            Keep it Safe 24/7 is not responsible for the custody of objects, and does not respond in case of loss, theft or damage, whatever its cause, nor the
            potential losses that may arise for users.
        </p>
        <p>
            Users of the lockers are entirely responsible for any damage caused to lockers, as well as any loss caused to Keep it Safe 24/7 or third parties from
            the use of lockers or deposited items.
        </p>

        <h2>9. ASSISTANCE</h2>
        <p>
            In case of failure, doubts or others, please contact our customer service through Whatsapp, under the phone number + 351 935899178 or via email
            keepitsafe247@hotmail.com.
        </p>

        <h2>10. SECURITY</h2>
        <p>
            For users’ safety, Keep it Safe 24/7 is under monitoring 24/7 by a closed-circuit television system (CCTV). Keep it Safe 24/7 lockers are built with
            durable materials, suitable for use in public places.
        </p>
        <p>
            Keep it Safe 24/7 reserves the right, in the presence of authority, to examine the contents of the lockers, when there is strong suspicion of
            breaches of the standards listed above. Such breaches imply immediate removal of the items, and responsibility of user for any damages caused.
        </p>

        <h2>11. CONTACTS</h2>
        <p>
            We have special plans for tour operators, AL’s (Alojamento Local), among others, with benefits for both parties. For more information about
            partnerships, please contact us through the email keepitsafe247@hotmail.com.
        </p>
    </div>
);

export default TermsConditions;