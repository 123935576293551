import React, {useState} from 'react';
import * as yup from 'yup';
import {useModal} from "./ModalContext";

const registerSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

const RegisterForm = ({onRegister}) => {
    const [userData, setUserData] = useState({email: '', password: '', confirmPassword: ''});
    const [errors, setErrors] = useState({});
    const {setShowRegisterModal, setShowLoginModal} = useModal();

    const handleSwitchToLogin = () => {
        setShowRegisterModal(false);
        setShowLoginModal(true);
    };
    const handleChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value});
        setErrors({...errors, [e.target.name]: ''});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedUserData = {
                ...userData,
                username: userData.email,
            };
            await registerSchema.validate(updatedUserData, {abortEarly: false});
            setErrors({});
            onRegister(updatedUserData);
            setShowRegisterModal(false);
        } catch (yupError) {
            if (yupError.inner) {
                const yupErrors = yupError.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {});
                setErrors(yupErrors);
            }
        }
    };

    return (
        <div className={"formContainer"}>
            <h1>Sign Up</h1>
            <p className={"greetings"}>Welcome to Keep It Safe 24/7! <br/>
                Sign up to book a locker.</p>
            <form onSubmit={handleSubmit}>
                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            placeholder={"Email"}
                        />
                    </div>
                    {errors.email && <p className="errorMessage">{errors.email}</p>}
                </div>
                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="password"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            placeholder={"Password"}
                        />
                    </div>
                    {errors.password && <p className="errorMessage">{errors.password}</p>}
                </div>

                <div className={"fieldContainer"}>
                    <div className={"inputContainer"}>
                        <input
                            type="password"
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            placeholder={"Confirm Password"}
                        />
                    </div>
                    {errors.confirmPassword && <p className="errorMessage">{errors.confirmPassword}</p>}
                </div>

                <button className={"c-button formButton"} type="submit">Sign Up</button>
            </form>
            <p className="switchFormText">
                Already have an account? <button className={"b-button"} onClick={handleSwitchToLogin}>Login</button>
            </p>
        </div>
    );
};

export default RegisterForm;
