import React, {useEffect, useState} from 'react';
import './Navbar.scss';
import RegisterForm from "../Auth/RegisterForm";
import LoginForm from "../Auth/LoginForm";
import {Link} from "react-router-dom";
import {useUser} from "../Auth/UserContext";
import {useModal} from "../Auth/ModalContext";
import {handleLogin, handleRegister} from "../../services/api";
import PasswordRetrieval from "../Auth/PasswordRetrieval";
import {useLocation} from 'react-router-dom';
import PasswordReset from "../Auth/PasswordReset";
import Cookies from "js-cookie";
import Modal from "../Modal/Modal";
import DeletionModal from "./DeleteAccountModal";

const Navbar = () => {
    const {user, handleUserUpdate, handleLogout} = useUser();
    const location = useLocation();
    const onRentalsPage = location.pathname === '/rentals';
    const [showDropdown, setShowDropdown] = useState(false);
    const [deletionMessage, setDeletionMessage] = useState('');
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const {
        showRegisterModal,
        setShowRegisterModal,
        showLoginModal,
        setShowLoginModal,
        showPasswordRetrievalModal,
        setShowPasswordRetrievalModal,
        authSuccessCallback,
        setAuthSuccessCallback,
        openPasswordResetModal,
        showPasswordResetModal,
        closePasswordResetModal,
        resetCode,
        showDeletionModal,
        setShowDeletionModal,
    } = useModal();

    const onLogin = async (userData) => {
        const {userDetails, error} = await handleLogin(userData);

        if (!error) {
            handleUserUpdate(userDetails);
            authSuccessCallback?.();
            setAuthSuccessCallback(null);
            setShowLoginModal(false);
            return {error: null};
        } else {
            return {error};
        }
    };

    const onRegisterSuccess = (userDetails) => {
        handleUserUpdate(userDetails);
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        if (code) {
            openPasswordResetModal(code);
        }
    }, [location.search]);

    const removeCodeFromURL = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('code');
        window.history.replaceState(null, null, location.pathname + (queryParams.toString() ? `?${queryParams.toString()}` : ''));
    };

    const handleClosePasswordResetModal = () => {
        removeCodeFromURL();
        closePasswordResetModal();
    };

    const onRegister = async (userData) => {
        const {error} = await handleRegister(userData, onRegisterSuccess);

        if (!error) {
            authSuccessCallback?.();
            setAuthSuccessCallback(null);
            setShowRegisterModal(false);
        }
    };
    const handleAccountDeletion = async () => {
        const isConfirmed = window.confirm("Are you sure you want to delete your account?");
        if (!isConfirmed) {
            return;
        }
        const token = Cookies.get('jwt');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users-permissions/requestAccountDelete`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to send account deletion request');
            }
            setDeletionMessage("Your account deletion request has been successfully submitted. We will process your request promptly.");
            setShowDeletionModal(true);

        } catch (error) {
            console.error('Error sending account deletion request:', error);
            setDeletionMessage("An error occurred while processing your request. Please try again later.");
            setShowDeletionModal(true);
        }
    };

    return (
        <div className="navbar">
            <div className={"navbarSubcontainer"}>
                <div className="navbar-logo">
                    <Link to="/">
                        <img src="/imgs/logo.svg" alt="KeepItSafe"/>
                    </Link>
                </div>
                <div className={`navbarLinks ${onRentalsPage ? 'rentals' : ''}`}>
                    {user ? (
                        <>
                            {onRentalsPage ? (
                                <div className="dropdown">
                                    <img src="/imgs/cog.svg" alt="" onClick={toggleDropdown}/>
                                    {showDropdown && (
                                        <div className="dropdown-menu">
                                            <button onClick={handleLogout}>Logout</button>
                                            <button className={"deleteMyAccount"} onClick={handleAccountDeletion}>Delete My Account</button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <button className="myLockersButton">
                                    <Link to="/rentals">
                                        <img src="/imgs/locker.svg" alt=""/> My Lockers
                                    </Link>
                                </button>
                            )}
                        </>
                    ) : (
                        <div className={"authContainer"}>
                            <button id={'loginButton'} onClick={() => setShowLoginModal(true)}>Login</button>
                            <button id={'signinButton'} onClick={() => setShowRegisterModal(true)}>Sign Up</button>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={showRegisterModal}
                onClose={() => setShowRegisterModal(false)}> <RegisterForm onRegister={onRegister} onRegisterSuccess={onRegisterSuccess}/></Modal>
            <Modal isOpen={showLoginModal}
                   onClose={() => setShowLoginModal(false)}><LoginForm onLogin={onLogin}/> </Modal>
            <Modal isOpen={showPasswordRetrievalModal}
                   onClose={() => setShowPasswordRetrievalModal(false)}><PasswordRetrieval/></Modal>
            <Modal isOpen={showPasswordResetModal}
                   onClose={handleClosePasswordResetModal}>
                <PasswordReset onClose={handleClosePasswordResetModal} code={resetCode} onResetSuccess={() => {
                    handleClosePasswordResetModal();
                    setShowLoginModal(true);
                }}/> </Modal>
            <Modal isOpen={showDeletionModal}
                   onClose={() => setShowDeletionModal(false)}>
                <DeletionModal
                    message={deletionMessage}
                />
            </Modal>
        </div>
    );
};

export default Navbar;

