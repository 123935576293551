import React from "react";
import {getEndTime} from '../../services/timeFormat';

const SummaryModalContent = ({selectedLocker, selectedDuration, handleCheckout, setShowSummaryModal}) => {

    const getTotalPrice = (locker, duration) => {
        if (!locker || !duration) return '';
        const price = locker.prices[duration];

        return price ? `${price}€` : '';
    };
    return (
        <div className="summaryContent">
            <h1>You're about to book</h1>
            <div className={"summaryInfoContainer"}>
                <div className={"summaryLockerImageContainer"}>
                    <div className={"summarySizeDimensionsMobile"}>
                        <span className={"summarySize mobile"}>{selectedLocker?.size}</span>
                        <p className={"summaryDimensions mobile"}>{selectedLocker?.dimensions}</p>
                    </div>
                    <img src={selectedLocker?.imageSrc} alt={selectedLocker?.altText}/>
                </div>
                <div className={"summaryInfo"}>
                    <span className={"summarySize desktop"}>{selectedLocker?.size}</span>
                    <p className={"summaryDimensions desktop"}>{selectedLocker?.dimensions}</p>
                    <div className={"summaryDurationContainer"}>
                        <p className={"summaryDuration"}>Pack {selectedDuration}</p>
                        <p>starting now</p>
                        <p>ends at {getEndTime(selectedDuration)}</p>
                    </div>
                    <p className={"totalValue"}>Total: {getTotalPrice(selectedLocker, selectedDuration)}</p>
                    <div className={"summaryButtonContainer"}>
                        <button
                            className="c-button"
                            onClick={() => {
                                setShowSummaryModal(false);
                                handleCheckout(selectedLocker?.stripeIds[selectedDuration]);
                            }}>
                            Proceed to Payment
                        </button>
                        <div className={"paymentMethodsContainer"}>
                            <img src={"/imgs/visa.svg"} alt={"Visa"}/>
                            <img src={"/imgs/mastercard.svg"} alt={"Mastercard"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"summaryWarningContainer"}>
                <p>
                    Before proceeding, please be aware of our locker rental policy regarding reservation timings: <br/>
                    1. Fixed Overtime Fee: If you exceed the booked time for your locker, a fixed fee of €2 will be charged for every additional half-hour beyond your reserved period.<br/>
                    2. Grace Period: Understanding that unexpected delays can occur, we offer a grace period of 5 minutes. If you exceed your booking time by up to 5 minutes, no additional charges will be applied.<br/>
                    We encourage you to manage your time efficiently to avoid any extra charges. Thank you for choosing our services!
                </p>
            </div>
        </div>

    );
};

export default SummaryModalContent;