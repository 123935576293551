import React, {useState} from 'react';

const PasswordRetrieval = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    const handlePasswordResetRequest = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: email}),
            });

            if (response.ok) {
                setMessage("If an account exists for this email, a password reset link has been sent.");
            } else {
                setMessage("Failed to send password reset link. Please try again later.");
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setMessage("An error occurred while trying to send the password reset link. Please try again later.");
        }
    };


    return (
        <div className={"formContainer"}>
            <h1>Reset Password</h1>
            <p className={"greetings"}>Lost your password?<br/>
                Dont worry, enter your email and a link will be sent with instructions.</p>
            <div className={"fieldContainer"}>
                <div className={"inputContainer"}>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={"Email"}
                    />
                </div>
            </div>
            <button className={"c-button formButton"} onClick={handlePasswordResetRequest}>Reset Password</button>
            <p style={{textAlign: "center"}}>{message}</p>
        </div>
    );
};

export default PasswordRetrieval;
