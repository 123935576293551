import React, {useState} from 'react';
import FaqItem from './FaqItem';
import {motion} from 'framer-motion';

const faqs = [
    {question: 'What is Keep it Safe 24/7?', answer: 'We provide a service where you can store your belongings in lockers, while taking advantage of the best attractions in Madeira Island.'},
    {question: 'When can I make the reservation?', answer: 'You can only pay when you are about to enter our store so we can guarantee that your locker is available'},
    {question: 'When can I access the locker I have hired?', answer: 'Lockers will be accessible anytime during the time previously booked. You may return and collect your items at all times.'},
    {question: 'How do the lockers work?', answer: 'Our lockers are 100% intelligent. This means that access to them is only possible via smartphone. Steps to use the lockers are available at the front door of the store and on the website.'},
    {question: 'What can I do if I have any problems using the locker?', answer: 'If you have any issues while booking or using our lockers, please reach out to us using the whatsapp number available on the website. We will get to you as soon as possible.'},
];

const additionalFaqs = [
    {question: 'How many lockers and sizes do we have?', answer: 'We have 30 lockers in 3 different sizes.'},
    {
        question: 'What can be stored in each locker?',
        answer: 'Medium Locker (M):<br/>' +
            '<ul>' +
            '<li>30 cm high; 50 cm wide; 85 cm deep.</li>' +
            '<li>This locker fits 1 hand suitcase and 1 backpack.</li>' +
            '</ul>' +
            'Large Locker (L)<br/>' +
            '<ul>' +
            '<li>50 cm high; 50 cm wide; 85 cm deep.</li>' +
            '<li>This locker fits: 2 hand suitcases + 3 backpacks OR 1 hold suitcase + 2 backpacks</li>' +
            '</ul>' +
            'Extra Large Locker (XL)<br/>' +
            '<ul>' +
            '<li>60 cm high; 50 cm wide; 85 cm deep.</li>' +
            '<li>This locker fits: 4 hand suitcases OR 1 extra large suitcase</li>' +
            '</ul>'
    },
    {question: 'What can I keep in my locker?', answer: 'You can keep anything that fits inside the hired locker, with the exception of prohibited items. Remember that with Keep it Safe 24/7 you pay per hired locker and not per item, so you can leave as many items as your locker fits'},
    {question: 'What is prohibited to store in my locker?', answer: 'Prohibited items include: animals, flammable products, batteries, electronic cigarettes, liquids and any item that may damage the lockers in any way.'},
    {question: 'Is it secure?', answer: 'Totally! Keep it Safe 24/7 has video surveillance cameras and systems which are connected to security 24 hours a day.'},
    {question: 'How do I know if Keep it Safe 24/7 has any lockers available?', answer: `Through the website <a href="https://keepitsafe24-7.com/" >Keep It Safe 24/7</a> you will be able to know the availability of lockers at that moment.`},
    {question: 'What happens if my luggage does not fit in the selected Locker?', answer: 'You must not force the door or the lock of the Locker to make your personal belongings fit. If the locker does not fit as many items as you intended you should book an extra locker. Misuse can break the door and Keep it Safe 24/7 will charge you up to €250 for repair'},
    {question: 'How long can I stay on the premises?', answer: 'Our store is designed only to leave and collect items. Any other activities such as eating, drinking and sleeping are not allowed'},
    {question: 'Can I leave items that will be collected by someone else?', answer: 'No. It is strictly forbidden to leave items for other people to collect, in order to prevent any type of smuggling'},
    {question: 'What should I do if I have an incident while using the locker?', answer: 'In case of breakdown, blockage or any other type of incident in the use of the locker, you have at your disposal the assistance service of Keep it Safe 24/7, which attends only by WhatsApp to the number (...) from 9:00 am to 21:00 pm every day.'},
    {
        question: 'What happens if I arrive after the time my booking ended?', answer: 'When your booking has ended and the system verifies that you have not picked up your personal belongings, a penalty charge of 2€ will be made in a range between 1-30 minutes and so on. You will have 5 minutes after your time has ended to collect your belongings but after that the 2€ penalty will be applied. This payment will automatically appear on the website according to the delay time.<br/>' +
            '<b>You will be able to enter in our store, but you will not be able to access your locker unless you pay the penalty</b>'
    },
    {question: 'What happens if I forgot something in my Locker?', answer: 'You must send an email to keepitsafe247@hotmail.com with your booking details and the address where you want us to send your forgotten item. After verifying that it is correct we will contact you for the management, you must pay all shipping costs, which will be paid in advance.'},

];
const Faqs = () => {
    const [visibleAdditionalFaqs, setVisibleAdditionalFaqs] = useState(0);

    const showMoreFaqs = () => {
        setVisibleAdditionalFaqs(prevVisible => prevVisible + 3);
    };
    // Animation variants for new FAQ items
    const newFaqItemVariants = {
        hidden: {opacity: 0, y: 20},
        visible: {opacity: 1, y: 0}
    };
    return (
        <div className="FaqsContainer container">
            <h1>FAQ's</h1>
            <div className="faqSubContainer">
                {faqs.map((faq, index) => (
                    <FaqItem key={index} question={faq.question} answer={faq.answer}/>
                ))}
                {additionalFaqs.slice(0, visibleAdditionalFaqs).map((faq, index) => (
                    <motion.div
                        key={`additional-${index}`}
                        variants={newFaqItemVariants}
                        initial="hidden"
                        animate="visible"
                        transition={{duration: 0.8, delay: index * 0.2}}
                    >
                        <FaqItem question={faq.question} answer={<div dangerouslySetInnerHTML={{__html: faq.answer}}/>}/>
                    </motion.div>
                ))}
            </div>
            {visibleAdditionalFaqs < additionalFaqs.length && (
                <button onClick={showMoreFaqs}>See more <img src="/imgs/arrow.svg" alt={"↓"}/></button>
            )}
        </div>
    );
};

export default Faqs;
